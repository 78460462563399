@import '/src/variables.less';

.mapping-edit-item-modal {

  // Cleanup the tabs as the margin makes things weird
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      margin-top: unset !important;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  // This messy selector is to NOT select the sub <Tabs> component
  .ant-tabs.mapping-item-tabs > .ant-tabs-nav {

    // These tabs are positioned on the left and need some adjustments given the way we want to render the text
    .ant-tabs-tab-btn {
      width: 100%;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      background-color: #131112;

      .ant-tabs-tab-btn {
        color: @accentLight;
      }
    }
  }
}

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: #dad9da;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@table-bg: unset;@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: rgba(24,123,179,0.25);@table-header-bg: #dad9da;@table-header-sort-active-bg: #C8C4C6;@table-header-sort-bg: #E1DFE0;@table-body-sort-bg: #dad9da;@table-border-color: black;