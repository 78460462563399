.login-flow {
  width: 333px;

  .company-logo {
    width: 64px;
    padding-bottom: 16px;
  }

  .ant-space {
    width: 300px;
  }
}

// Centers the two factor input on the page itself
.login-two-factor-page, .password-reset-form {
  .auth-code-input {
    align-items: center;
    justify-content: center;
  }
}

.login-account-selection-page {
  max-width: 700px;
}

.login-location-selection-page {
  max-width: 1110px;
}

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: #dad9da;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@table-bg: unset;@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: rgba(24,123,179,0.25);@table-header-bg: #dad9da;@table-header-sort-active-bg: #C8C4C6;@table-header-sort-bg: #E1DFE0;@table-body-sort-bg: #dad9da;@table-border-color: black;