/* Layout */
// Fixes layout to give a static header and sidenav with scrolling content
.user-layout {
  height: 100vh;
}

.content-container {
  height: 100vh;
  overflow-x: hidden;
}

/* User Layout */
.user-layout {

  /* Headers */
  .content-layout {
    .spacer {
      // font-weight: bold;
      font-size: 16px;
      margin: 0px 4px;
    }
    header.top-header {
      background-color: @primary;
      height: @headerTopHeight;
      line-height: @headerTopHeight;
    }
    header.bottom-header {
      background-color: @primaryAccent;
      height: @headerBottomHeight;
      line-height: @headerBottomHeight;
      font-size: 12px;
    }
  }

  // Adds standard padding to the content area
  .content-container {
    padding: 20px 16px 48px;
  }
}

.public-layout {
  height: 100vh;

  // Adds background to the layout
  background: linear-gradient(180deg, #187BB3 0%, #194E83 100%);

  // Fun hack to have a double background WITH opacity: https://www.digitalocean.com/community/tutorials/how-to-change-a-css-background-images-opacity
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;

    background-image: url('/assets/company-logo-white.svg');
    background-size: 66%;
    // background-position: calc(50vw - 75px) calc(50vh + 50px);
    background-position: bottom -40vh right -20vw; // This seemed to have the best overall behavior so it is the winner for now
    background-repeat: no-repeat;

    // Make background un-selectable, which seems crazy. However, scrolling is terribly broken without this
    user-select: none;
    pointer-events: none;
  }

  // Adds better scrolling (mostly due to the content-container and inner-container)
  .content-container {
    // Fixes the double background being on top of the content. Another very strange css thing that seems like it should not be
    position: relative;

    .inner-container {
      position: relative; // Fix for floating items, such as the button on the terms of service page
      margin: 40px auto;
      padding: 36px 40px 16px;
      background-color: @componentBackground;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);

      // Odd display type but it shows the margin at the bottom properly
      display: table;

      // Adds rounded corners
      border-radius: 8px;
      // Centers text since the components will be centered anyway
      text-align: center;
    }
  }

  .lower-logo-container {
    text-align: end;

    .lower-logo {
      margin-top: 12px;
      height: 28px;
      margin-right: -16px;
    }
  }

}

.print-layout {
  // padding: 20px;
  // padding-top: 12px;
  background-color: unset;
  color: black;

  header {
    background-color: unset;
    color: black;
    line-height: 16px;

    .content {
      margin-left: 4px;
    }

    // Make the row in the header max height and the logo a tad larger
    > div {// please change
      height: 100%;

      .text-column {
        line-height: 18px;
        font-weight: 700;
      }
      .logo-column {
        height: 100%;
      }
    }
  }

  main {
    margin-top: 20px;
    height: 100%;
    // .ant-table-thead > tr > th {
    //   background-color: unset;
    // }
  }

  @media print {
    .with-print-hidden {
       visibility: hidden;
    }
    .with-print-none {
       display: none;
    }

    .page-break {
      page-break-after: always;
    }
  }
}


/* SideNav */
.ant-menu {
  background-color: @primaryDark;

  // Long weird class name stuff because of overrides
  &.ant-menu-inline-collapsed > li.ant-menu-item {
    // Add slight padding to better center the icons
    padding-top: 4px;

    // Change the selected color
    &.ant-menu-item-selected {
      background-color: @primary;
    }
  }
}

// Idk what this is for. Might be an artifact from the expanded sidenav?
.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: white;
}

// Removes the 1px border that exists for some reason on the right of the sidenav
.ant-menu-inline, .ant-menu-vertical {
  border: unset;
}

.user-side-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  // Give the upper and lower sections some room
  .upper-section {

    // Some magic for the header image
    .header-image-container {
      text-align: center;
      padding: 8px 8px 0px 8px;
      // Dev Note: Why such a weird calc? Well, I don't know. The heights make sense, css just doesn't and there is a phantom few pixels getting added
      height: calc(@headerTopHeight + @headerBottomHeight - 4px);

      img {
        display: block;
        margin: auto;
        width: 100%;
        // height: 100%;
      }
    }

    // This had something todo with the expandable sidenav, I don't recall anymore
    // &.ant-layout-sider-collapsed {
    //   img {
    //     width: 100%;
    //   }
    // }
  }

  .lower-section {
    text-align: center;
    padding-bottom: 8px;

    // This makes the avatar on the bottom of the screen not selectable. So, if a user drags or double-clicks, it won't select random text on the page
    .ant-avatar-string {
      user-select: none;
    }
  }
}

// The dropdown menu (has to be out here since dropdown's are outside of the main DOM elements)
.sidenav-dropdown-menu {
  box-shadow: unset;
  background-color: #e6e6e6;

  // Increase the size of the icons
  .ant-dropdown-menu-item-icon {
    font-size: 20px;
    min-width: 20px;
  }
  .ant-dropdown-menu-title-content {
    font-size: 14px;
  }
}
