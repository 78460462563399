@import '/src/variables.less';

// Variables first!
@marginExtraSmall: 4px;
@marginSmall: 8px;
@marginMedium: 16px;
@marginLarge: 24px;
@marginExtraLarge: 32px;

@paddingExtraSmall: 4px;
@paddingSmall: 8px;
@paddingMedium: 16px;
@paddingLarge: 24px;
@paddingExtraLarge: 32px;

@fontSizeSmall: 12px;
@fontSizeMedium: 14px;
@fontSizeLarge: 18px;
@fontSizeExtraLarge: 22px;

@standardBorderSmall: 1px solid black;
@standardBorderMedium: 2px solid black;
@standardBorderLarge: 3px solid black;


/* These are opinionated standard classes */
.with-standard-margin {
  margin: @marginMedium @marginSmall;

  &-small { margin: @marginSmall @marginExtraSmall; }
  &-medium { margin: @marginMedium @marginSmall; }
  &-large { margin: @marginLarge @marginMedium; }
  &-extra-large { margin: @marginExtraLarge @marginLarge; }
}
.with-standard-padding {
  padding: @paddingMedium @paddingSmall;

  &-small { padding: @paddingSmall @paddingExtraSmall; }
  &-medium { padding: @paddingMedium @paddingSmall; }
  &-large { padding: @paddingLarge @paddingMedium; }
  &-extra-large { padding: @paddingExtraLarge @paddingLarge; }
}

/* Margin */
.with-margin {
  margin: @marginMedium;

  &-small { margin: @marginSmall; }
  &-medium { margin: @marginMedium; }
  &-large { margin: @marginLarge; }
  &-extra-large { margin: @marginExtraLarge; }

  &-top {
    margin-top: @marginMedium;

    &-small { margin-top: @marginSmall; }
    &-medium { margin-top: @marginMedium; }
    &-large { margin-top: @marginLarge; }
    &-extra-large { margin-top: @marginExtraLarge; }
  }
  &-bottom {
    margin-bottom: @marginMedium;

    &-small { margin-bottom: @marginSmall; }
    &-medium { margin-bottom: @marginMedium; }
    &-large { margin-bottom: @marginLarge; }
    &-extra-large { margin-bottom: @marginExtraLarge; }
  }
  &-left {
    margin-left: @marginMedium;

    &-small { margin-left: @marginSmall; }
    &-medium { margin-left: @marginMedium; }
    &-large { margin-left: @marginLarge; }
    &-extra-large { margin-left: @marginExtraLarge; }
  }
  &-right {
    margin-right: @marginMedium;

    &-small { margin-right: @marginSmall; }
    &-medium { margin-right: @marginMedium; }
    &-large { margin-right: @marginLarge; }
    &-extra-large { margin-right: @marginExtraLarge; }
  }
  &-x, &-horizontal {
    margin-left: @marginMedium;
    margin-right: @marginMedium;

    &-small { margin-left: @marginSmall; margin-right: @marginSmall; }
    &-medium { margin-left: @marginMedium; margin-right: @marginMedium; }
    &-large { margin-left: @marginLarge; margin-right: @marginLarge; }
    &-extra-large { margin-left: @marginExtraLarge; margin-right: @marginExtraLarge; }
  }
  &-y, &-vertical {
    margin-top: @marginMedium;
    margin-bottom: @marginMedium;

    &-small { margin-top: @marginSmall; margin-bottom: @marginSmall; }
    &-medium { margin-top: @marginMedium; margin-bottom: @marginMedium; }
    &-large { margin-top: @marginLarge; margin-bottom: @marginLarge; }
    &-extra-large { margin-top: @marginExtraLarge; margin-bottom: @marginExtraLarge; }
  }
}

.without-margin {
  margin: unset;

  &-force, &-important { margin: unset !important; }

  &-top {
    margin-top: unset;

    &-force, &-important { margin-top: unset !important; }
  }
  &-bottom {
    margin-bottom: unset;

    &-force, &-important { margin-bottom: unset !important; }
  }
  &-left {
    margin-left: unset;

    &-force, &-important { margin-left: unset !important; }
  }
  &-right {
    margin-right: unset;

    &-force, &-important { margin-right: unset !important; }
  }
  &-x, &-horizontal {
    margin-left: unset;
    margin-right: unset;

    &-force, &-important { margin-left: unset !important; margin-right: unset !important; }
  }
  &-y, &-vertical {
    margin-top: unset;
    margin-bottom: unset;

    &-force, &-important { margin-top: unset !important; margin-bottom: unset !important; }
  }
}

/* Padding */
.with-padding {
  padding: @paddingMedium;

  &-small { padding: @paddingSmall; }
  &-medium { padding: @paddingMedium; }
  &-large { padding: @paddingLarge; }
  &-extra-large { padding: @paddingExtraLarge; }

  &-top {
    padding-top: @paddingMedium;

    &-small { padding-top: @paddingSmall; }
    &-medium { padding-top: @paddingMedium; }
    &-large { padding-top: @paddingLarge; }
    &-extra-large { padding-top: @paddingExtraLarge; }
  }
  &-bottom {
    padding-bottom: @paddingMedium;

    &-small { padding-bottom: @paddingSmall; }
    &-medium { padding-bottom: @paddingMedium; }
    &-large { padding-bottom: @paddingLarge; }
    &-extra-large { padding-bottom: @paddingExtraLarge; }
  }
  &-left {
    padding-left: @paddingMedium;

    &-small { padding-left: @paddingSmall; }
    &-medium { padding-left: @paddingMedium; }
    &-large { padding-left: @paddingLarge; }
    &-extra-large { padding-left: @paddingExtraLarge; }
  }
  &-right {
    padding-right: @paddingMedium;

    &-small { padding-right: @paddingSmall; }
    &-medium { padding-right: @paddingMedium; }
    &-large { padding-right: @paddingLarge; }
    &-extra-large { padding-right: @paddingExtraLarge; }
  }
  &-x, &-horizontal {
    padding-left: @paddingMedium;
    padding-right: @paddingMedium;

    &-small { padding-left: @paddingSmall; padding-right: @paddingSmall; }
    &-medium { padding-left: @paddingMedium; padding-right: @paddingMedium; }
    &-large { padding-left: @paddingLarge; padding-right: @paddingLarge; }
    &-extra-large { padding-left: @paddingExtraLarge; padding-right: @paddingExtraLarge; }
  }
  &-y, &-vertical {
    padding-top: @paddingMedium;
    padding-bottom: @paddingMedium;

    &-small { padding-top: @paddingSmall; padding-bottom: @paddingSmall; }
    &-medium { padding-top: @paddingMedium; padding-bottom: @paddingMedium; }
    &-large { padding-top: @paddingLarge; padding-bottom: @paddingLarge; }
    &-extra-large { padding-top: @paddingExtraLarge; padding-bottom: @paddingExtraLarge; }
  }
}

.without-padding {
  padding: unset;

  &-force, &-important { padding: unset !important; }

  &-top {
    padding-top: unset;

    &-force, &-important { padding-top: unset !important; }
  }
  &-bottom {
    padding-bottom: unset;

    &-force, &-important { padding-bottom: unset !important; }
  }
  &-left {
    padding-left: unset;

    &-force, &-important { padding-left: unset !important; }
  }
  &-right {
    padding-right: unset;

    &-force, &-important { padding-right: unset !important; }
  }
  &-x, &-horizontal {
    padding-left: unset;
    padding-right: unset;

    &-force, &-important { padding-left: unset !important; padding-right: unset !important; }
  }
  &-y, &-vertical {
    padding-top: unset;
    padding-bottom: unset;

    &-force, &-important { padding-top: unset !important; padding-bottom: unset !important; }
  }
}

/* Display */
.with-display {
  &-inline { display: inline; }
  &-block { display: block; }
  &-contents { display: contents; }
  &-flex { display: flex; }
  &-grid { display: grid; }
  &-inline-block { display: inline-block; }
  &-inline-flex { display: inline-flex; }
  &-inline-grid { display: inline-grid; }
  &-inline-table { display: inline-table; }
  &-list-item { display: list-item; }
  &-table { display: table; }
  &-table-cell { display: table-cell; }
  &-table-column { display: table-column; }
  &-table-row { display: table-row; }
  &-none { display: none; }
}

/* Border */
.with-border {
  border: @standardBorderSmall;

  &-small { border: @standardBorderSmall; }
  &-medium { border: @standardBorderMedium; }
  &-large { border: @standardBorderLarge; }

  &-top {
    border-top: @standardBorderSmall;

    &-small { border-top: @standardBorderSmall; }
    &-medium { border-top: @standardBorderMedium; }
    &-large { border-top: @standardBorderLarge; }
  }
  &-bottom {
    border-bottom: @standardBorderSmall;

    &-small { border-bottom: @standardBorderSmall; }
    &-medium { border-bottom: @standardBorderMedium; }
    &-large { border-bottom: @standardBorderLarge; }
  }
  &-left {
    border-left: @standardBorderSmall;

    &-small { border-left: @standardBorderSmall; }
    &-medium { border-left: @standardBorderMedium; }
    &-large { border-left: @standardBorderLarge; }
  }
  &-right {
    border-right: @standardBorderSmall;

    &-small { border-right: @standardBorderSmall; }
    &-medium { border-right: @standardBorderMedium; }
    &-large { border-right: @standardBorderLarge; }
  }
  &-x, &-horizontal {
    border-left: @standardBorderSmall;
    border-right: @standardBorderSmall;

    &-small { border-left: @standardBorderSmall; border-right: @standardBorderSmall; }
    &-medium { border-left: @standardBorderMedium; border-right: @standardBorderMedium; }
    &-large { border-left: @standardBorderLarge; border-right: @standardBorderLarge; }
  }
  &-y, &-vertical {
    border-top: @standardBorderSmall;
    border-bottom: @standardBorderSmall;

    &-small { border-top: @standardBorderSmall; border-bottom: @standardBorderSmall; }
    &-medium { border-top: @standardBorderMedium; border-bottom: @standardBorderMedium; }
    &-large { border-top: @standardBorderLarge; border-bottom: @standardBorderLarge; }
  }
}

/* Text adjustments (color, style, size, etc) */
.with-text {
  &-color {
    &-green { color: @success; }
    &-red { color: @danger; }
    // &-light-grey { color: @danger; }
    &-dark-grey { color: @decentlyDark; }
  }

  &-style {
    &-bold { font-weight: 700; }
    &-bolder { font-weight: 800; }
  }

  &-align {
    &-left { text-align: left; }
    &-right { text-align: right; }
    &-center { text-align: center; }
    &-justify { text-align: justify; }
  }

  &-uppercase { text-transform: uppercase; }
  &-lowercase { text-transform: lowercase; }
}

.with-font-size {
  &-small { font-size: @fontSizeSmall; }
  &-medium { font-size: @fontSizeMedium; }
  &-large { font-size: @fontSizeLarge; }
  &-extra-large { font-size: @fontSizeExtraLarge; }
}

.with-overflow {
  &-x { overflow-x: auto; }
  &-y { overflow-y: auto; }
}

.with-whitespace-break {
  white-space: pre-line;
}


// And that is where I stopped condensing things haha...


// Backgrounds
.with-background-light-grey {
  background-color: @lightGrey;
}

// Misc

// A grid container but all the items have the same width
.with-grid-layout-equal {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.with-grid-layout-half {
  display: grid;
  grid-template-columns: 50% 50%;

  grid-auto-flow: column;
  // grid-auto-columns: 1fr;
}

// Adds standard flex container things
.with-flex-layout {
  display: flex;
  flex-direction: column;
}

// Adds a white background to the given icon as they are transparent. Why? No idea
.standard-icon-rounded {
  background-color: @accentLight;
  border-radius: 50%;
}
