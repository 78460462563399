.debug-overlay {
  padding: 16px;

  &.modal-edition {
    z-index: 1001;
    top: 0;
    right: 0;

    border: 1px solid black;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.45);

    min-width: 366px;
    max-width: 600px;
    min-height: 200px;
    background-color: white;
    position: absolute;
  }

  // Handles the line items
  .ant-descriptions-item-container,
  .ant-descriptions-row {

    // Make all text just a bit smaller
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      font-size: 12px;
    }

    // This chunk is for handling the more/less button on the top right of the box
    .ant-descriptions-item-content {
      position: relative;
      padding: 4px 8px;

      .more-less-button {
        position: absolute;
        top: 0;
        right: 0;
        height: 18px;
        padding: 0 15px;
        background-color: white;
        line-height: 1;
        font-weight: 500;
      }
    }

    // Make the titles less prone to word breaks
    .inner-title {
      flex-shrink: 0;
      word-break: keep-all;
    }
    .inner-content {
      word-break: break-all;
      display: flex;
      align-items: center;
    }
  }
}

// Handles the show/hide logic
.show-debug-panel {
  z-index: 1002;
  top: 0;
  right: 0;
  height: 24px;
  width: 36px;
  margin: 4px;
  position: absolute;
  overflow: hidden;
  padding: unset;
  opacity: 0.05; // Make it hard to see in case it makes it out to dev
}

.debug-panel-buttons {
  top: 0;
  right: 0;
  margin: 4px;
  position: absolute;
  overflow: hidden;
  padding: unset;

  // Shrink up the buttons just a tad
  .ant-btn {
    padding: 0 12px;
  }
}

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: #dad9da;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@table-bg: unset;@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: rgba(24,123,179,0.25);@table-header-bg: #dad9da;@table-header-sort-active-bg: #C8C4C6;@table-header-sort-bg: #E1DFE0;@table-body-sort-bg: #dad9da;@table-border-color: black;