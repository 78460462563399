@import '~antd/lib/style/themes/default.less'; // Needed if you want to use ant's less variables
@import '/src/variables.less';
@import '/src/withStyles.less';
@import '/src/layouts/layouts.less';

/* Main */
h1 {
  font-size: 28px;
  line-height: 28px;
}
h2 {
  font-size: 24px;
  line-height: 21px;
}
h3 {
  font-size: 18px;
  line-height: 28px;
}
p {
  font-size: 14px;
  line-height: 16px;
}

/* Checkbox */
// Vertical Checkboxes, inspired by https://stackoverflow.com/questions/53424246/ant-design-vertical-group-of-checkboxes
.ant-checkbox-group.vertical {
  .ant-checkbox-group-item {
    display: flex;
    margin-right: 0;
  }
}

/* Inputs */
// Dark Input, added per the original mocks
.ant-input.dark {
  background-color: #808080;
  color: white;

  // Had to hunt these down as it is a rather hidden property
  &::placeholder {
    color: mix(#ffffff, #808080, 50%); // Wow, this works actually pretty nicely
  }
}

/* Buttons */
.ant-btn.ant-btn-primary {
  // Adds a green button option
  &.green {
    background: @success;
    border: unset;
  }

  &.red {
    background: @danger;
    border: unset;
  }
}

/* Form */
// Vertical forms have a strange amount of label space
.ant-form-vertical .ant-form-item-label > label {
  height: unset;
}

// Make icons proper sized
.ant-form-item .form-icon {
  margin-right: 8px;
}
.ant-form-large .ant-form-item .form-icon {
  font-size: 32px;
}

// Class for the nested form items. Adds flex and space between
.form-item-grouped-flexed {
  margin-bottom: 0px;

  // This is unfortunate but otherwise, it will select into subsequent form items
  > div > div > div.ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;

    // Added proper inline display to contained items
    > * {
      display: inline-block;
    }
  }
}

// Class for the nested form items. Adds spacing without flex
.form-item-grouped-spaced {
  margin-bottom: 0px;

  > div > div > div.ant-form-item-control-input-content {
    // Added proper inline display to contained items
    > * {
      display: inline-block;
    }

    // All but first get a margin to space them apart
    > :not(:first-child) {
      margin-left: 20px;
    }
  }
}

/* Notifications */
.custom-error-container {
  // Set margin a bit slimmer than standard and remove it from the last element
  > * {
    margin-bottom: 8px;
  }
  :last-child {
    margin-bottom: unset;
  }
}

/* Alerts */
.inline-alert {
  display: inline-flex;
  padding: 4px 12px;
}



/* Tables */
// Client wants the headers to be larger. Bolding seems to work best, so sticking with that
.ant-table-thead th.ant-table-cell {
  line-height: 16px;
  font-weight: bold;

  // Change the sorter colors
  .ant-table-column-sorter {
    color: #999999;

    // Make them a tiny bit bigger as well
    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down {
      font-size: 14px;
    }
  }
}

// Fixes the auto scrollbar set when adding a scroll attribute on antd's <Table>
.ant-table-fixed-header .ant-table-body {
  // This is set as a style and set to scroll for some odd reason, so we need the !important 😔
  overflow-y: auto !important;
}

.condensed-table {
  // Need this nasty selector to avoid accidentally styling sub-tables
  > div > div > div > div > div > table {

    // Tighten up the padding here as well
    > .ant-table-thead > tr > th.ant-table-cell {
      padding: 6px;
    }

    // Remove the header separator (sorry for the nasty selector. Antd did this, not me)
    > .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      // Unsetting the content removes the element, which is nice
      content: unset;
    }

    // Bring the rows closer and remove borders
    > .ant-table-tbody > tr > td.ant-table-cell {
      padding: 0px 6px;
    }

    // Ensure expanded rows have proper padding
    > .ant-table-tbody > tr.ant-table-expanded-row > td.ant-table-cell {
      padding: 16px;
      background-color: @component-background; // Mocks wanted this, so we are doing it. Probably should move this to main table style
    }

    // Ensure footer gets same treatment as above
    > .ant-table-summary > tr > td.ant-table-cell {
      padding: 0px 6px;
    }
  }
}
//prevents page breaking inside our table rows
tr.ant-table-row.ant-table-row-level-0 {
  break-inside: avoid;
}



//give the assingment rows the same height as the other reports
.report-assignment >
  .condensed-table 
    > div > div > div > div > div > table {
    > .ant-table-tbody > tr > td.ant-table-cell {
      padding: 5px 6px;
      border: unset;
    }
  }
.report-assignment >
.condensed-table > div > div > div > div > div > table > .ant-table-tbody > tr.ant-table-expanded-row > td.ant-table-cell{
  padding:16px;
}
  
  

//force expanded assignment table to have condensed styling despite habing buttons
.assignment-expanded-table{
  a.ant-typography-ellipsis-single-line, span.ant-typography-ellipsis-single-line{
    height: 22px;
  }
  .ant-btn{
    height:22px;
    padding: 0px 4px;
  }
  td.ant-table-cell{
    padding: 0px 6px;
  }
} 

// A version of the condensed table with slightly larger padding. Mainly used on clickable tables, such as SelectLocation or PickList
.less-condensed-table {
  // Need this nasty selector to avoid accidentally styling sub-tables
  > div > div > div > div > div > table {

    // Tighten up the padding here as well
    > .ant-table-thead > tr > th.ant-table-cell {
      padding: 6px;
    }

    // Remove the header separator (sorry for the nasty selector. Antd did this, not me)
    > .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      // Unsetting the content removes the element, which is nice
      content: unset;
    }

    // Bring the rows closer and remove borders
    > .ant-table-tbody > tr > td.ant-table-cell {
      padding: 4px 6px;
      border: unset;
    }

    // Ensure expanded rows have proper padding
    > .ant-table-tbody > tr.ant-table-expanded-row > td.ant-table-cell {
      padding: 16px;
      background-color: @component-background; // Mocks wanted this, so we are doing it. Probably should move this to main table style
    }

    // Ensure footer gets same treatment as above
    > .ant-table-summary > tr > td.ant-table-cell {
      padding: 4px 6px;
    }
  }
}

.borderless-table {
  // Need this nasty selector to avoid accidentally styling sub-tables
  > div > div > div.ant-table > div.ant-table-container {
    // Remove the weird border from the left
    border: unset;

    > div.ant-table-content > table {
      // Remove the weird border from the top
      border: unset;
    }

    // Remove the weird border from the right
    > .ant-table-content > table > tbody > tr > td:last-child,
    > .ant-table-content > table > thead > tr > th:last-child {
      border: unset;
    }
  }

  /** These CANNOT be combined as they exhibit the perfect behavior as is **
    * The more specific selection above fixes sub tables, while the less specific selector below will allow bordered tables to function without absolutely atrocious not() selectors for the bordered table class
    */

  > div > div > div > div > div > table {
    // Remove the header separator (sorry for the nasty selector. Antd did this, not me)
    > .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      // Unsetting the content removes the element, which is nice
      content: unset;
    }

    > thead > tr > th.ant-table-cell {
      border: unset;
    }

    // Bring the rows closer and remove borders
    > tbody > tr > td.ant-table-cell {
      border: unset;
    }

    // Ensure footer gets same treatment as above
    > tfoot > tr > td.ant-table-cell {
      border-bottom: unset;
    }
  }
}

//This should fix sorting messing up our table css
td.ant-table-column-sort{
  background: unset;
}

.striped-table {
  // Need this nasty selector to avoid accidentally styling sub-tables
  > div > div > div > div > div > table {
    > .ant-table-tbody {
      > tr.striped-row {
        background-color: @stripedTableBackground;
      }
    }

    // Sorting can realllllly mess the css up, so we, once again, need to tell ant to piss off
    // But only so high up as the hover gets messed up if this is TOO specific, UHG
    // td.ant-table-column-sort {
    //   background-color: unset;
      
    // }
  }
}

/* Randoms */
.description {
  // background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // font-weight: 500;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);

  // background-color: #666666;
  // -webkit-background-clip: text;
  // -moz-background-clip: text;
  // background-clip: text;
  // color: transparent;
  // text-shadow: rgba(255,255,255,0.5) 0px 3px 3px;
}

// Misc color descriptors
.make-text-green {
  color: @success;
}
.make-text-red {
  color: @danger;
}
.make-text-dark-grey {
  color: @decentlyDark;
}
.make-text-bold, .with-bold-text {
  font-weight: bold;
}

.make-background-light-grey {
  background-color: @lightGrey;
}

// This is needed to avoid breaking the header
td.without-padding-table-cell {
  padding: unset !important;
}

// A grid container but all the items have the same width
.with-equal-grid-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

// with-no-padding for table elements is a bit more complicated
.ant-table-tbody > tr > td.with-no-padding, // These might be able to be removed, replaced with the lower ones
.ant-table-thead > tr > th.with-no-padding, // These might be able to be removed, replaced with the lower ones
.ant-table-tbody > tr > td.without-padding,
.ant-table-thead > tr > th.without-padding {
  padding: unset;
}

// Makes them a tab larger and adds margin to the left, since they are normally used after something
.standard-icon {
  margin-left: 8px;
  font-size: 16px;
}

.dimensionless-button {
  height: unset;
  width: unset;
  margin: unset;
  padding: unset;
  border: unset;
  display: block;
}

.large-icon {
  font-size: 22px;
  color: @inactive;
}

// Adds a white background to the given icon as they are transparent. Why? No idea
.icon-with-white-background {
  background-color: @accentLight;
  border-radius: 50%;

  // An attempt to use the :before attribute which failed. I guess svg does not like it or something. Either way, it almost worked
  // > :before {
  //   content: 'a';
  //   background: white;
  //   position: relative;
  //   left: -15px;
  //   top: -10px;
  //   width: 14px;
  //   height: 14px;
  //   display: inline-block;
  //   color: transparent;
  //   border-radius: 50%;
  // }
}

/** Pages **/

/* Terms of Service and Privacy Statement */
.terms-of-service, .privacy-statement {
  max-width: 750px;

  .large-text-container {
    max-height: 750px;
    overflow-y: auto;
    text-align: left;
  }
}

// Generic Form Layout
.standard-form-layout {
  .ant-form {
    margin: 0 16px;
    max-width: 600px;
  }
}

// Generic Table Layout
.standard-table-layout {
  max-width: 900px;
}

// select location layout
.location-table-layout {
  max-width: 1150px;
}

.password-reset-flow {
  width: 400px;
}

// Back button on the upper left. Mimics the modal close but on the other side
.enhanced-back-button {
  position: absolute;
  top: 0;
  left: 0;
  // z-index: 10;
  padding: 0;
  color: rgba(0,0,0,.45);
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color .3s;

  .button-back {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
}

// Change the modal max width to more or less avoid the sidebar
.ant-modal {
  max-width: calc(100vw - 180px);

  // The above css overrides the media, which is super strange, so we need to reimplement it
  @media (max-width: 767px) {
    max-width: calc(100vw - 16px);
  }

  // ALSO, the body is all sorts of "thick" so to make it less annoying, we are removing the bottom padding
  // Most components have bottom margin, so this change will just make is less.... thick
  .ant-modal-body {
    // padding-bottom: unset;
    padding-bottom: 1px;

    .ant-modal-confirm-btns {
      padding-bottom: 24px;
    }
  }
}

.ant-form-item-label > label .ant-form-item-tooltip {
  cursor: inherit;
}

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: #dad9da;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@table-bg: unset;@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: rgba(24,123,179,0.25);@table-header-bg: #dad9da;@table-header-sort-active-bg: #C8C4C6;@table-header-sort-bg: #E1DFE0;@table-body-sort-bg: #dad9da;@table-border-color: black;