.report-assignment {
  .wide-column {
    padding: 0px 32px;
  }
  .special-text {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.report-transaction {
  .special-text {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.report-journal {
  .special-text {
    display: grid;
    grid-template-columns: 1fr;
    // height: 22px;
  }
}

.standard-chevrons {
  font-size: 12px;
  vertical-align: middle;
}
@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: #dad9da;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@table-bg: unset;@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: rgba(24,123,179,0.25);@table-header-bg: #dad9da;@table-header-sort-active-bg: #C8C4C6;@table-header-sort-bg: #E1DFE0;@table-body-sort-bg: #dad9da;@table-border-color: black;