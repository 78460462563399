@import '/src/variables.less';

.activity-tile {
  // Keeps the tile within acceptable ranges
  max-width: 1160px;
  border: 1px solid @inactive;
  // Adds smoothness to the border hover
  transition: 0.25s;

  // This will act as the container for the CSS Grid
  display: grid;
  grid-template-columns: minmax(140px, 280px) minmax(auto, 700px) minmax(170px, 210px);
  grid-template-rows: auto;

  // Adds special hover color
  &:hover {
    // border-width: 2px;
    border-color: @decentlyDark;
    // margin: -1px;
  }

  // Totals column needs to be darkened slightly
  .is-totals-column {
    background-color: @lightGrey;
  }
  th.is-totals-column {
    background-color: @lightGrey !important; // override for the header row, which is hard to work with, see the condensed-table thingy below
  }

  // Darken the posted tiles
  &.is-posted {
    background-color: @lightGrey;
  }

  .item {
    &:first-child {
      grid-column: 1 / span 1;
      align-self: center;
      padding: 0px 16px;
    }
    &:nth-child(2) {
      grid-column: 2 / span 1;
    }
    &:last-child {
      grid-column: 3 / span 1;
      align-self: center;
      text-align: center;
      padding: 16px 20px;
    }
  }

  // Special considerations here to remove the table header color
  .condensed-table {
    // Adds some space for the table to breath
    padding: 8px 0px 12px;

    // Need this nasty selector to avoid accidentally styling sub-tables
    > div > div > div > div > div > table {

      // Remove colors and borders from header
      > .ant-table-thead > tr > th.ant-table-cell {
        border: unset;
        background: unset;
      }

      // // Help when coloring the totals row
      // > .ant-table-thead > tr > th.ant-table-cell.make-background-light-grey {
      //   background-color: @lightGrey;
      // }
    }
  }

  // Change the link text to black
  .ant-table-content > table, // The table
  .item { // The link on the right
    a {
      color: rgba(0, 0, 0, 0.85);
    }
    a:active, a:hover {
      text-decoration: underline;
    }
  }
}
@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: #dad9da;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@table-bg: unset;@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: rgba(24,123,179,0.25);@table-header-bg: #dad9da;@table-header-sort-active-bg: #C8C4C6;@table-header-sort-bg: #E1DFE0;@table-body-sort-bg: #dad9da;@table-border-color: black;